import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import HMenuItem from './HMenuItem';
import * as builder from '../../../ducks/builder';
import { toAbsoluteUrl } from '../../..';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';
import InfoIcon from '../InfoIcon';
import TermDialog from '../../../../app/components/other/Dialog/TermDialog';

const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active',
  },
};

class HMenu extends React.Component {
  offCanvasCommonRef = React.createRef();
  ktMenuCommonRef = React.createRef();

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
    }

  }

  componentDidMount() {
    // Canvas
    this.initOffCanvas();
    // Menu
    this.initKTMenu();
  }

  initOffCanvas = () => {
    new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
  };

  initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion',
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = 'accordion';
    if (this.ktMenuCommonRef.current.getAttribute('data-ktmenu-dropdown') === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    // eslint-disable-next-line no-undef
    new KTMenu(this.ktMenuCommonRef.current, menuOptions);
  };

  handleClickInfoBtn = () => {
    this.setState({ isOpenModal: !this.state.isOpenModal });
  };

  render() {
    const {
      disabledAsideSelfDisplay,
      ktMenuClasses,
      ulClasses,
      rootArrowEnabled,
      role
    } = this.props;
    const items = this.props.menuConfig?.aside?.items;    

    const onRefresh = () => {
      return (
        <a
          onClick={() => window.location.reload()}
          href='/products/catalog'
          style={{
            margin: 0,
            fontWeight: 'bold',
            fontSize: 15,
            color: '#000000',
            width: '120px',
          }}
        >
          Одежда оптом
        </a>
      );
    };

    return (
      <>
      {
        this.state.isOpenModal && (
          <TermDialog
            isOpen={this.state.isOpenModal}
            handleClose={this.handleClickInfoBtn}
            className='alert-dialog-container'
            contentType={role === 'ROLE_VENDOR' 
              ? 'document_for_saller'
              : 'document_for_buyer'
            }
            title={role === 'ROLE_VENDOR' 
              ? 'Важная информация для продавца'
              : 'Важная информация для покупателей'
            }
            maxWidth={'sm'}
          />
      )}
        <button className='kt-header-menu-wrapper-close' id='kt_header_menu_mobile_close_btn'>
          <i className='la la-close' />
        </button>
        <div
          className='kt-header-menu-wrapper'
          id='kt_header_menu_wrapper'
          ref={this.offCanvasCommonRef}
        >
          <div className='kt-header-mobile__toolbar'>
            {/* <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button> */}
          </div>

          {disabledAsideSelfDisplay && (
            <div className='kt-header-logo'>
              <Link to='/products/catalog'>
                <img
                  alt='logo'
                  src={toAbsoluteUrl('/media/logos/logo-opt1.png')}
                  height={50}
                />
              </Link>
            </div>
          )}
          {
           role !== 'ROLE_ADMIN' ? (
            <button onClick={this.handleClickInfoBtn} className='kt-header-info-btn'>
              <InfoIcon />
              <span>Информация</span>
            </button>
        ) : null}

          <div
            id='kt_header_menu'
            className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
            ref={this?.ktMenuCommonRef}
          >
            
            <ul className={`kt-menu__nav ${ulClasses}`}>
              {items?.map((item, index) => {
                              
                return (
                  <React.Fragment key={`hmenuList${index}`}>
                    {item.title && (
                      <HMenuItem
                        userRole={role}
                        item={item}
                        currentUrl={this.currentUrl}
                        rootArrowEnabled={rootArrowEnabled}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: 'header_menu',
    toString: true,
  }),
  rootArrowEnabled: builder.selectors.getConfig(store, 'header.menu.self.root-arrow'),
  headerSelfSkin: builder.selectors.getConfig(store, 'header.self.skin'),
  ulClasses: builder.selectors.getClasses(store, {
    path: 'header_menu_nav',
    toString: true,
  }),
  disabledAsideSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false,
  role: store.profile?.me?.roles[0] ?? '',

});

export default withRouter(connect(mapStateToProps)(HMenu));
